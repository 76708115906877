import React from 'react'
import { Box, Typography } from '@material-ui/core'
import ProductItem from 'container/product/ProductItem'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  item: {
    flex: '0 0 33.33%',
    padding: '5px',
    justifyContent: 'center',
    marginBottom: '10px'
  }
})

const AsianTrendList = ({
  classes,
  list,
  actionProductItem
}) => {
  return (
    <>
      {list && list.length >= 0 ? (
        <>
          <Box ml={1} mb={0.5}>
            <Typography color="textPrimary" style={{ fontWeight: 'bold' }}>
              Best seller this week
            </Typography>
          </Box>
          <div className={classes.container}>
            {list.map((record, index) => {
              return (
                <div key={index} className={classes.item}>
                  <ProductItem
                    {...actionProductItem()}
                    stateName="productsignature"
                    quantity={record.cartQty}
                    item={record}
                  />
                </div>
              )
            })}
          </div>

        </>
      ) : null}
    </>
  )
}

export default withStyles(styles)(AsianTrendList)
