import {
  getHomeAnnouncement
} from 'services/home/homeService'
import {
  FETCH_ANNOUNCEMENT,
  RECEIVE_ANNOUNCEMENT,
  FAILED_ANNOUNCEMENT
} from '../types'

const fetch = () => {
  return {
    type: FETCH_ANNOUNCEMENT
  }
}

const receive = (data) => {
  return {
    type: RECEIVE_ANNOUNCEMENT,
    payload: {
      data
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_ANNOUNCEMENT,
    payload: {
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const get = () => async (dispatch) => {
  dispatch(fetch())
  const response = await getHomeAnnouncement()
  if (response && response.success && response.data && response.data.length > 0) {
    dispatch(receive(response.data))
  } else {
    dispatch(failed(response))
  }
}

export {
  get
}
