import React from 'react'
import Container from 'container/home/Container'
import CustomHelmet from 'components/CustomHelmet'

import { getWeeklyTrend, getProductSignature } from 'services/home/homeService'
import { getUserTokenServer } from 'utils/storage'
import { encrypt } from 'actions/utils/encryptAction'
import { withRouter } from 'next/router'

class AppIndex extends React.Component {
  static async getInitialProps (ctx) {
    const { req } = ctx
    const token = getUserTokenServer(req)

    let listWeekly = await getWeeklyTrend({ page: 1 }, token)

    if (listWeekly && listWeekly.success && listWeekly.data && listWeekly.data.length > 0) {
      listWeekly = listWeekly.data
    } else {
      listWeekly = []
    }

    let listSignature = await getProductSignature({ page: 1, lastId: 0 }, token)

    if (listSignature && listSignature.success && listSignature.data && listSignature.data.length > 0) {
      listSignature = listSignature.data
    } else {
      listSignature = []
    }
    return { listWeekly, listSignature }
  }

  componentDidMount () {
    const { router } = this.props
    const { query } = router
    if (query && query.tableId && query.storeId) {
      const dineincipher = encrypt(JSON.stringify(query))
      window.sessionStorage.setItem('DINE_IN', dineincipher)
    }
  }

  render () {
    const { listWeekly, listSignature } = this.props
    const containerProps = {
      listWeekly, listSignature
    }
    return (
      <div>
        <CustomHelmet
          title="K3Mart"
          description="K3Mart"
        />
        <Container {...containerProps} />
      </div>
    )
  }
}

export default withRouter(AppIndex)
