import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomHelmet from 'components/CustomHelmet'
import BottomNavigation from 'components/BottomNavigation'
import HeaderNavigation from 'components/HeaderNavigation'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarItem from 'components/Alert/SnackbarItem'
import { withStyles } from '@material-ui/core/styles'
import { mapDataDelivery, getAddressDineIn, mapDataDineIn } from 'actions/selectAddress/selectAddressAction'
import { updateState as updateStateHome } from 'actions/home/homeAction'
import { shoppingCartAdd } from 'actions/shoppingCart/shoppingCartAction'
import { getStoreMap } from 'actions/storeMap/storeMapAction'
import { encrypt, decrypt } from 'actions/utils/encryptAction'
import Image from 'next/image'
import Router, { withRouter } from 'next/router'
import { getUserToken } from 'utils/storage'
import color from 'theme/color'
// import SetAddress from './SetAddress'
import Content from './Content'

const useStyles = () => ({
})

class Container extends Component {
  componentDidMount () {
    const { router } = this.props
    const { query } = router
    if (query && query.tableId && query.storeId) {
      const dineincipher = encrypt(JSON.stringify(query))
      window.sessionStorage.setItem('DINE_IN', dineincipher)
    }

    this.getHomeData()
  }

  getHomeData = async () => {
    const { dispatch } = this.props
    let decipher = window.sessionStorage.getItem('DINE_IN')

    dispatch(shoppingCartAdd())
    dispatch(getStoreMap())
    if (decipher) {
      try {
        const dineInString = decrypt(decipher)
        const dineIn = JSON.parse(dineInString)
        const token = await getUserToken()
        if (!token) {
          Router.push('/auth/login?source=g')
        } else {
          Router.replace('/')
        }

        dispatch(mapDataDineIn(dineIn))
      } catch (error) {
        dispatch(mapDataDelivery({ firstLoad: 1 }))
      }
    } else {
      dispatch(mapDataDelivery({ firstLoad: 1 }))
    }
  }

  getAddressDineIn = (storeId, tableId) => {
    const {
      dispatch
    } = this.props

    dispatch(getAddressDineIn(storeId, tableId))
  }

  clearError = () => {
    const {
      dispatch
    } = this.props

    dispatch(updateStateHome({
      errorMessage: ''
    }))
  }

  render () {
    const { title, errorMessage } = this.props
    return (
      <div>
        <CustomHelmet
          title={title}
        />
        <HeaderNavigation
          customColor={color.primaryColor}
          xsIconLeft={7}
          xsIconCenter={5}
          // iconLeft={<SetAddress />}
          iconLeft={<div />}
          centerExtra={(
            <>
              <div style={{
                display: 'flex', width: '100%', justifyContent: 'end'
              }}
              >
                <Image
                  loader={({ src }) => {
                    return src
                  }}
                  unoptimized
                  src="/static/app/logo_header_small.png"
                  alt="logo_small"
                  width={148}
                  height={44}
                />
              </div>
            </>
          )}
        />

        <Content {...this.props} />

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={!!errorMessage}
          autoHideDuration={3000}
          onClose={() => { this.clearError() }}
        >
          <SnackbarItem
            variant="error"
            message={errorMessage}
          />
        </Snackbar>

        <BottomNavigation value="/home" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  errorMessage: state.homeStore.errorMessage
})

export default connect(mapStateToProps)(withStyles(useStyles)(withRouter(Container)))
