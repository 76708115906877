import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import color from 'theme/color'
import config from 'utils/config'
import Modal from 'components/Modal'
import { setDineInStatus } from 'actions/storeMap/storeMapAction'
// import Image from 'next/image'
import Html5QrcodePlugin from 'utils/Html5QrcodePlugin'
import { mapDataDelivery, removeDineIn } from 'actions/selectAddress/selectAddressAction'
// import DeliveryButton from './DeliveryButton'
import DineInButton from './DineInButton'
import CancelButton from './CancelButton'
import SadFaceIcon from '../../../static/Icon/svg/SadFaceIcon'

const styles = () => ({
  container: {
    margin: '2em 0.8em',
    padding: '0.5em',
    borderRadius: '6px',
    backgroundColor: color.colorRed,
    border: '0.1px solid #BCBCBC'
  },
  buttonDelivery: {
    backgroundColor: 'white',
    display: 'block',
    width: '100%',
    borderRadius: '6px'
  },
  buttonDineIn: {
    backgroundColor: 'white',
    display: 'block',
    width: '100%',
    borderRadius: '6px'
  },
  cardRow: {
    display: 'flex'
  },
  item: {
    flexGrow: 1,
    padding: '5px',
    cursor: 'pointer'
  },
  item2: {
    flexGrow: 2,
    padding: '5px'
  },
  item3: {
    flexGrow: 3,
    padding: '5px'
  },
  labelLoading: {
    fontWeight: '700',
    padding: '0px 10px'
  },
  labelSubLoading: {
    fontWeight: '400',
    padding: '0px 10px'
  }
})

class DeliveryDinein extends Component {
  state = {
    isVisibleModalDineIn: false,
    isVisibleModalQr: false,
    isScanning: false
  }

  setDineIn = (value) => {
    const {
      dispatch
    } = this.props

    this.setState({ isScanning: true })

    if (value === false) {
      window.sessionStorage.removeItem('DINE_IN')
      dispatch(setDineInStatus(value))
      dispatch(mapDataDelivery({ firstLoad: 0 }))
      this.setState({ isScanning: false })
    } else if (value === true) {
      this.setState((state) => ({ isVisibleModalQr: !state.isVisibleModalQr }))
    }
  }

  confirmSetDineIn = () => {
    this.setState({ isVisibleModalDineIn: true })
  }

  onNewScanResult = (decodedText) => {
    if (decodedText && decodedText.includes(config.SUB_URL)) {
      this.setState((state) => ({ isVisibleModalQr: !state.isVisibleModalQr }))
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = decodedText
      a.click()
      document.body.removeChild(a)
    }
  }

  render () {
    const { isVisibleModalQr, isVisibleModalDineIn, isScanning } = this.state
    const {
      classes,
      currentStore,
      storeMap,
      operationalHour,
      dineInAddress,
      dispatch
    } = this.props

    const modalDineInProps = {
      title: 'Batalkan Dine In',
      content: 'Apakah anda yakin ?',
      visible: isVisibleModalDineIn,
      onOk: () => {
        this.setState({
          isVisibleModalDineIn: false
        })
        this.setDineIn(false)
        dispatch(removeDineIn())
      },
      onCancel: () => {
        this.setState({
          isVisibleModalDineIn: false
        })
      }
    }

    const checkCameraPermission = async () => {
      await navigator.permissions.query({ name: 'camera' })
        .then((permmissionObj) => {
          if (permmissionObj.state === 'granted') {
            return true
          }
          if (permmissionObj.state === 'prompt') {
            navigator.mediaDevices.getUserMedia({ audio: false, video: true })
          }
          if (permmissionObj.state === 'denied') {
            alert(`${window.location.hostname} need permission to access your camera!`)
            return false
          }
        })
        .catch((error) => {
          throw error
        })
    }

    return (
      <div className={classes.container}>
        {isVisibleModalQr && checkCameraPermission() && (
          <Html5QrcodePlugin
            fps={10}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={this.onNewScanResult}
          />
        )}
        {isVisibleModalDineIn && <Modal.Confirm {...modalDineInProps} />}
        {!storeMap.dineInStatus ? (
          <div className={classes.cardRow}>
            {/* <div className={classes.item}
              onClick={() => {
                if (isScanning) {
                  this.setState({
                    isScanning: false,
                    isVisibleModalQr: false
                  })
                }
              }}
            >
              <DeliveryButton />
            </div> */}
            <div className={classes.item} style={{ pointerEvents: isScanning ? 'none' : 'unset' }}>
              <DineInButton setDineIn={this.setDineIn} val />
            </div>
          </div>
        ) : (
          <div className={classes.cardRow}>
            {/* <div className={classes.item}>
              <DineInButton val={false} />
            </div> */}
            <div className={classes.item}>
              <CancelButton setDineIn={() => this.confirmSetDineIn()} val={false} />
            </div>
          </div>
        )}

        {currentStore && currentStore.name ? (
          <div className={classes.cardRow}>
            <div style={{ display: 'flex' }} className={classes.item}>
              {/* <div>
                <Image
                  style={{ width: '63px', height: '63px', borderRadius: '6px' }}
                  loader={({ src }) => {
                    return src
                  }}
                  unoptimized
                  src="/static/app/store_photo.png"
                  alt="store_photo"
                  width={90}
                  height={90}
                />
              </div> */}
              <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
                {dineInAddress && dineInAddress.tableName ? (
                  <div style={{
                    display: 'flex',
                    fontWeight: 'bolder',
                    color: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '30px'
                  }}
                  >
                    {dineInAddress.tableName}
                  </div>
                ) : null}
                <div style={{ fontWeight: 'bolder', color: 'white', fontSize: '15px' }}>
                  K3 Mart
                  {' '}
                  {currentStore.name}
                </div>
                <div style={{ fontWeight: 'bolder' }}>
                  {operationalHour.openHour !== undefined && operationalHour.closeHour !== undefined ? (
                    <>
                      Jam Operasional
                      {' '}
                      {operationalHour.openHour > 10 ? `${operationalHour.openHour}:00` : `0${operationalHour.openHour}:00`}
                      -
                      {operationalHour.closeHour > 10 ? `${operationalHour.closeHour}:00` : `0${operationalHour.closeHour}:00`}
                    </>
                  ) : (null)}
                </div>
                <div>
                  {currentStore.address}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.cardRow}>
            <div style={{ display: 'flex' }} className={classes.item}>
              <div>
                <SadFaceIcon style={{ width: '63px', height: '63px' }} />
              </div>
              <div style={{ paddingLeft: '10px' }}>
                <div style={{ fontWeight: 'bolder', color: '#FFDB56', fontSize: '15px' }}>
                  Area Belum Terjangkau!
                </div>
                <div style={{ fontWeight: 'bolder', color: 'white' }}>
                  Maaf, tidak ada K3 Mart yang dekat
                </div>
                <div style={{ color: 'white' }}>
                  dengan tempat kamu
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  shoppingCartId: state.shoppingCartStore.id,
  listAddress: state.selectAddressStore.list,
  mainAddress: state.selectAddressStore.mainAddress,
  currentStore: state.storeStore.currentStore,
  operationalHour: state.storeStore.operationalHour,
  dineInAddress: state.selectAddressStore.dineInAddress,
  storeMap: state.storeMapStore
})

export default connect(mapStateToProps)(withStyles(styles)(DeliveryDinein))
