import React from 'react'

export default function SadFaceIcon () {
  return (
    <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_383_30)">
        <path d="M63 31.5C63 42.0759 57.788 51.4345 49.7926 57.1473C44.6359 60.8319 38.3212 61.3751 31.5 61.3751C14.1031 61.3751 2.8656 50.2266 2.8656 32.8297C2.8656 22.5368 4.93674 12.0672 12.5718 6.31901C17.8412 2.35166 24.396 0 31.5 0C48.897 0 63 14.103 63 31.5V31.5Z" fill="#FFD764" />
        <path d="M52.8275 54.682C47.2158 59.8462 39.7268 63 31.5 63C14.1034 63 0 48.8966 0 31.5C0 20.051 6.10732 10.0293 15.243 4.5152C8.98859 10.2727 5.07042 18.5273 5.07042 27.6972C5.07042 45.0938 19.1738 59.1972 36.5704 59.1972C42.518 59.1972 48.0815 57.548 52.8275 54.682Z" fill="#FFAA4D" />
        <path d="M37.3676 7.44287C36.4064 7.01568 35.2843 7.44844 34.8577 8.40802C34.0972 10.1189 32.3873 11.2094 30.5152 11.1774L25.1226 11.0852C25.0316 11.0843 24.9254 11.088 24.8327 11.0972C23.4721 11.234 22.4799 12.4479 22.6166 13.8085C22.6404 14.0457 22.697 14.2717 22.7814 14.4824C23.181 15.4808 24.6345 15.7462 25.758 15.6334C25.758 15.6334 29.7314 15.2616 32.9327 14.944C34.7556 14.7633 36.2201 13.9666 36.956 12.289L37.9053 10.1746C38.3141 9.25491 38.3615 7.96284 37.4846 7.49978C37.4466 7.47963 37.4075 7.46061 37.3676 7.44287V7.44287Z" fill="#B36D3C" />
        <path d="M38.3324 9.95324L37.3208 12.259C36.5856 13.9373 35.0011 15.087 33.1783 15.2683C29.9776 15.5865 25.328 16.0251 25.328 16.0251C24.2049 16.1366 23.1807 15.4813 22.7814 14.4824C25.5688 14.2175 30.7445 13.7269 33.0033 13.5025C34.186 13.3847 35.2179 12.6355 35.6957 11.5466L37.4843 7.50043C38.3615 7.9631 38.7418 9.03423 38.3324 9.95324V9.95324Z" fill="#995E34" />
        <path d="M45.6525 8.03343C46.5152 7.4317 47.699 7.64377 48.2999 8.50511C49.3711 10.0408 51.2567 10.7872 53.0888 10.4007L58.3659 9.28747C58.4552 9.26935 58.5601 9.25274 58.6529 9.24437C60.0148 9.12053 61.2192 10.1242 61.343 11.4861C61.3657 11.7353 61.3506 11.9792 61.3025 12.2123C61.0875 13.2527 59.5158 13.6767 58.4031 13.7778C58.4031 13.7778 54.9826 14.0651 51.7792 14.3604C49.9551 14.5286 48.2095 14.313 47.1689 12.8056L45.7379 10.7325C45.1634 9.90898 44.7745 8.73759 45.5433 8.11583C45.5783 8.08718 45.6147 8.0598 45.6525 8.03343V8.03343Z" fill="#B36D3C" />
        <path d="M61.3014 12.2121C61.0872 13.2528 60.2138 14.0742 59.1009 14.1756C59.1009 14.1756 54.4526 14.6269 51.2493 14.9222C49.4252 15.0908 47.6519 14.2618 46.6124 12.7534L45.1813 10.6808C44.6071 9.85815 44.7744 8.73758 45.5438 8.11646L48.0727 11.7456C48.6875 12.6367 49.7016 13.1691 50.7828 13.1691C50.8843 13.1691 50.9857 13.1641 51.0858 13.1552C53.493 12.9334 58.8524 12.439 61.3014 12.2121V12.2121Z" fill="#995E34" />
        <path d="M23.8309 22.0516C26.9552 18.9793 31.9784 19.0214 35.0507 22.1456C37.7208 24.8607 38.0386 29.0102 36.0254 32.0669C35.7221 32.5274 34.9918 32.8158 34.5827 33.218C31.4585 36.2903 27.3955 35.5983 24.3232 32.474C21.2509 29.3497 20.7066 25.1239 23.8309 22.0516V22.0516Z" fill="#FCF7ED" />
        <path d="M49.202 22.0516C52.3263 18.9793 57.3495 19.0214 60.4218 22.1456C63.4941 25.2698 63.4522 30.2931 60.3279 33.3654C57.2036 36.4377 49.5998 32.6677 49.5998 32.6677C49.5998 32.6677 46.0777 25.1239 49.202 22.0516V22.0516Z" fill="#FCF7ED" />
        <path d="M36.2713 31.6686C35.9202 32.2771 35.4816 32.85 34.9568 33.3659C31.8321 36.4374 26.8086 36.3955 23.7372 33.2721C20.6645 30.1475 20.7064 25.124 23.831 22.0513C24.3228 21.5683 24.8616 21.1614 25.432 20.8331C23.6954 23.8437 24.0972 27.7568 26.6527 30.3566C29.2412 32.9882 33.2151 33.4319 36.2713 31.6686Z" fill="#F7E5C1" />
        <path d="M61.6424 31.6686C61.2913 32.2771 60.8527 32.85 60.3279 33.3659C57.2032 36.4374 52.1797 36.3955 49.1083 33.2721C46.0356 30.1475 46.0775 25.124 49.2021 22.0513C49.6939 21.5683 50.2327 21.1614 50.8031 20.8331C49.0665 23.8437 49.4683 27.7568 52.0238 30.3566C54.6122 32.9882 58.5862 33.4319 61.6424 31.6686Z" fill="#F7E5C1" />
        <path d="M56.1312 29.0416C56.7378 28.4449 57.5478 28.1848 58.3339 28.2543C58.4453 28.2642 58.5509 28.197 58.5888 28.0918C59.3718 25.9189 58.8015 23.3646 56.8638 21.7431C54.7483 19.9728 51.6167 19.9865 49.5121 21.7696C46.9416 23.9475 47.4107 27.1383 49.7294 29.496C51.3482 31.1423 52.6447 31.66 54.6833 30.9255C54.7884 30.8876 54.8557 30.7824 54.8458 30.6711C54.7894 30.0345 55.4139 29.9908 55.7918 29.4446C55.8901 29.3025 56.0031 29.1674 56.1312 29.0416V29.0416Z" fill="#8C818F" />
        <path d="M55.3107 31.2795C55.3196 31.3897 55.2524 31.4962 55.1472 31.533C53.1089 32.2682 50.7397 31.8081 49.121 30.1614C47.0827 28.0889 46.9445 24.8578 48.6875 22.6281C48.4225 24.4357 48.9777 26.3421 50.3582 27.7454C51.8261 29.2386 53.8783 29.8065 55.7924 29.4452C55.4134 29.9903 55.2537 30.6431 55.3107 31.2795V31.2795Z" fill="#766D78" />
        <path d="M51.8654 25.1916C50.9429 26.1141 50.7537 26.0393 49.8311 25.1167C48.9087 24.1943 48.9087 22.6986 49.8311 21.7762C50.1222 21.4852 49.7938 21.6083 50.1628 21.5008C50.9637 21.2676 51.8638 21.4669 52.4952 22.0983C53.0717 22.6748 53.288 23.4753 53.1439 24.2198C53.0574 24.6666 52.2113 24.8457 51.8654 25.1916V25.1916Z" fill="#FCF7ED" />
        <path d="M53.1355 24.2607C53.0443 24.6929 52.8313 25.1036 52.4954 25.4396C51.5726 26.3611 50.0768 26.3611 49.1552 25.4396C48.2324 24.5167 48.2324 23.021 49.1552 22.0981C49.4468 21.8066 49.7941 21.6076 50.163 21.5011C50.002 22.2579 50.215 23.0767 50.8031 23.6649C51.4344 24.2962 52.3344 24.4952 53.1355 24.2607V24.2607Z" fill="#F7E5C1" />
        <path d="M25.643 38.2275C25.643 38.9187 25.4185 39.5576 25.0385 40.0751C24.4697 40.8496 23.6423 40.7698 22.6077 40.7698C20.8819 40.7698 19.7596 40.0143 19.7596 38.2886C19.7596 36.8388 21.5852 33.7121 22.2907 32.5924C22.3901 32.4348 22.6199 32.4348 22.7192 32.5924C22.8577 32.8121 23.0668 32.5776 23.2927 32.9542C24.2183 34.4972 25.643 37.0622 25.643 38.2275V38.2275Z" fill="#86BFF2" />
        <path d="M25.0378 40.0744C24.4699 40.8489 23.5535 41.3522 22.5178 41.3522C20.7926 41.3522 19.3932 39.9528 19.3932 38.2275C19.3932 36.7808 21.5875 33.155 22.2987 32.018C22.3989 31.8578 22.6323 31.8591 22.7302 32.0207C22.8686 32.2494 23.0653 32.5754 23.2923 32.9543C22.4925 34.3905 21.6749 36.0764 21.6749 36.9599C21.6749 38.6851 23.0743 40.0846 24.7995 40.0846C24.8794 40.0846 24.9592 40.082 25.0378 40.0744Z" fill="#62A4FB" />
        <path d="M48.1893 41.7605C47.8851 41.7605 47.586 41.6147 47.4021 41.3441C46.1619 39.5192 44.1851 38.4726 41.9787 38.4726C39.7723 38.4726 37.7954 39.5192 36.5552 41.3441C36.26 41.7784 35.6689 41.8913 35.2345 41.5961C34.8002 41.301 34.6875 40.7096 34.9826 40.2754C36.559 37.9559 39.1742 36.5712 41.9787 36.5712C44.7831 36.5712 47.3984 37.9559 48.9747 40.2754C49.2698 40.7096 49.157 41.301 48.7228 41.5961C48.5592 41.7073 48.3732 41.7605 48.1893 41.7605V41.7605Z" fill="#E27C48" />
        <path d="M29.9934 28.2789C30.5281 27.7531 31.2401 27.5209 31.9332 27.5763C32.0483 27.5855 32.1528 27.5138 32.1913 27.4049C32.8515 25.538 32.3933 23.3615 30.8055 21.9214C28.9226 20.2136 26.014 20.1698 24.0825 21.8221C21.8129 23.7636 22.2328 26.6589 24.2908 28.7517C25.7265 30.2118 26.9737 30.787 28.7837 30.1434C28.8892 30.1059 28.9555 30.0003 28.9465 29.8888C28.8982 29.2872 29.3817 29.0326 29.7688 28.5319C29.8369 28.4437 29.9118 28.3591 29.9934 28.2789V28.2789Z" fill="#8C818F" />
        <path d="M29.2614 30.2489C29.2703 30.3604 29.2044 30.4669 29.0979 30.5037C27.2877 31.1476 25.1899 30.7356 23.7537 29.2753C22.0069 27.4994 21.8269 24.7677 23.1997 22.7953C23.059 24.3177 23.5648 25.8883 24.7208 27.0634C26.0822 28.4476 28.0077 28.9394 29.7697 28.5325C29.3818 29.032 29.2132 29.648 29.2614 30.2489V30.2489Z" fill="#766D78" />
        <path d="M26.8154 25.0297C25.8929 25.9522 24.0967 25.1916 24.0967 25.1916C24.0967 25.1916 22.8182 23.5504 23.7407 22.628C23.997 22.3717 24.3395 21.6569 24.6598 21.5431C25.492 21.2472 26.4569 21.4323 27.123 22.0984C27.6995 22.6749 27.9158 23.4754 27.7716 24.2199C27.6852 24.6666 27.1613 24.6838 26.8154 25.0297V25.0297Z" fill="#FCF7ED" />
        <path d="M27.7631 24.2607C27.6718 24.6929 27.4589 25.1036 27.123 25.4396C26.2001 26.3611 24.7044 26.3611 23.7828 25.4396C22.86 24.5167 22.86 23.021 23.7828 22.0981C24.0744 21.8066 24.4217 21.6076 24.7906 21.5011C24.6296 22.2579 24.8425 23.0767 25.4307 23.6649C26.062 24.2962 26.962 24.4952 27.7631 24.2607V24.2607Z" fill="#F7E5C1" />
      </g>
      <defs>
        <clipPath id="clip0_383_30">
          <rect width="63" height="63" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
